import jwt from 'jsonwebtoken';

/**
 * Create a locally signed token from a JWT token
 * NOTE: Only use this for testing purposes
 * @param token
 * @returns {*}
 */
export const getLocallySignedToken = (token) => {
    const decoded = jwt.decode(token);
    if (decoded) {
        return jwt.sign(decoded, "mysupersecretkeymysupersecretkey")
    }
};
